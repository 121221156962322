import React from "react"
import { Helmet } from "react-helmet"
import config from "../../../site.config"

/** components */
import Layout from "../../components/Layout"
import { theme } from "../../components/Theme"

/** static sections */
import NewsletterSignup from "../../components/static-sections/NewsletterSignupSection"
import CompareSection from "../../components/static-sections/CompareSection"

/** dynamic sections */
import HeroTopSection from "../../components/dynamic-sections/HeroTopSection"
import RibbonSection from "../../components/dynamic-sections/RibbonSection"
import SixImageSection from "../../components/dynamic-sections/SixImageSection"
import GalleryImageSection from "../../components/dynamic-sections/GalleryImageSection"
import AwardsSection from "../../components/dynamic-sections/AwardsSection"
import GoogleMapSection from "../../components/dynamic-sections/GoogleMapSection"
import HeroRightSection from "../../components/dynamic-sections/HeroRightSection"
import FourImageSmallSection from "../../components/dynamic-sections/FourImageSmallSection"

/** color theme */
const colorPalette = theme.colorPalettes.blue

/** svg */
import NorhartIcon from "../../../assets/norhart-icon.svg"
import ApartmentIcon from "../../../assets/apartment-icon.svg"
import BadgeCheckIcon from "../../../assets/badge-check-icon.svg"
import NorhartDarkCircleIcon from "../../../assets/norhart-dark-circle-icon.svg"

/** props */
interface Props {
  data: any
}

/** const */
const ForestLakeLocationPage: React.FC<Props> = (props) => {
  const ldJson = {
    "@context": "http://schema.org",
    "@type": "BreadcrumbList",
    itemListElement: [
      {
        "@type": "ListItem",
        position: 1,
        item: {
          "@id": "/locations/forest-lake/",
          name: "Forest Lake Location | Norhart",
          image: `${config.siteMetadata.siteUrl}/locations/forest-lake/forest-lake-hero-open-graph.png`,
        },
      },
    ],
  }

  return (
    <Layout
      title="Forest Lake Apartments Locations | Norhart"
      description="Forest Lake is a city in Washington County, Minnesota. Forest Lake is located 30 miles from Minneapolis, and the population is 20,933. Norhart HQ is also located in Forest Lake!"
      keywords="norhart, luxury, innovative, smart, technology, apartment, apartments, building, buildings, mn, minnesota, minneapolis, st paul, rent, rentals, resident, residents, forest lake, blaine, circle pines, lexington, coon rapids, Oakdale, encore apartments, lexington lofts apartments, Oakdale apartments, gateway green apartments, mill pond apartments, birchview apartments, legacy pointe apartments, heritage heights apartments, springwood apartments, greystone apartments, northbrook apartments, construction, norhart construction, lean construction"
      imageTwitter="/locations/forest-lake/forest-lake-hero-twitter-card.png"
      imageOpenGraph="/locations/forest-lake/forest-lake-hero-open-graph.png"
      colorPalette={colorPalette}
    >
      <HeroTopSection
        title="Forest Lake, MN"
        subtitle="Experience What Forest Lake Has To Offer"
        imageTitle="Experience What Forest Lake Has To Offer"
        image="/locations/forest-lake/forest-lake-location-hero.png"
        colorPalette={colorPalette}
      />

      <RibbonSection
        title="Forest Lake Apartments"
        tagLine="Apartments that will improve your life"
        colorPalette={colorPalette}
      />

      <Helmet>
        <script type="application/ld+json">{JSON.stringify(ldJson)}</script>
      </Helmet>

      <GoogleMapSection
        icon={NorhartDarkCircleIcon}
        iconTitle="Forest Lake, MN"
        title="Norhart Invite's You To Explore Forest Lake"
        subtitle="Forest Lake is a city in Washington County, Minnesota. Forest Lake is located 30 miles from Minneapolis and the population is 20,933. Norhart HQ is also located in Forest Lake!"
        query="Forest Lake,+MN+Encore"
        colorPalette={colorPalette}
      />

      <SixImageSection
        backgroundColorOne={colorPalette.heroTop}
        backgroundColorTwo={colorPalette.heroBottom}
        fontTextColor="#FFFFFF"
        icon={NorhartDarkCircleIcon}
        iconTitle="Norhart Forest Lake"
        title="Forest Lake Is As Good As It Sounds"
        subtitle="Forest Lake is a welcoming community full of great people, jobs, and lake recreational activities. The city is also the birthplace of Norhart. Plus, our main HQ is here as well! #GoNorhart!"
        imageTitleOne="Lakeside Memorial Park"
        imageOneText="The most popular downtown destination in Forest Lake is Lakeside Memorial Park. There, you’ll have access to the beach, picnic shelters, a playground, yard space, restaurants, and shopping."
        imageOne="/locations/forest-lake/lakeside-memorial-park.png"
        imageOneAlt="Forest Lake Lakeside Memorial Park"
        imageOnePill=""
        imageTitleTwo="Tanners Brook Golf Club"
        imageTwoText="Surrounded by the serenity of the North Woods, on the outskirts of Forest Lake, are 6,887 yards of manicured greens. Tanners Brook Golf Club offers five sets of tees, definitely worth checking out."
        imageTwo="/locations/forest-lake/tanners-brook.png"
        imageTwoAlt="Forest Lake Tanners Brook Golf Club"
        imageTwoPill=""
        imageTitleThree="Daniel A. DePonti Memorial Airport"
        imageThreeText="On average, 22 flights occur each day at the Daniel A. DePonti Memorial Airport. They provide infrastructure for the region’s corporate and leisure aviation needs, relieving congestion at the MSP Airport."
        imageThree="/locations/forest-lake/airport.png"
        imageThreeAlt="Forest Lake Daniel A. DePonti Memorial Airport"
        imageThreePill=""
        imageTitleFour="Running Aces Casino & Racetrack"
        imageFourText="Located just outside of Forest Lake, off I-35, is Running Aces Casino & Racetrack. Just as you would expect, there are card tables, food, and entertainment. There are many activities and events that you can take part in."
        imageFour="/locations/forest-lake/running-aces.png"
        imageFourAlt="Forest Lake Running Aces Casino & Racetrack"
        imageFourPill=""
        imageTitleFive="Transit Center & Forest Lake P&R"
        imageFiveText="If you are looking for a ride, conveniently located on highway 61 is a Metro Transit Park & Ride. Get to where you need to go without the hassle. When it’s time to come back look for stop number: 52883."
        imageFive="/locations/forest-lake/transit-center.png"
        imageFiveAlt="Forest Lake Transit Center & Forest Lake P&R"
        imageFivePill=""
        imageTitleSix="Hardwood Creek Trail"
        imageSixText="The Forest Lake Hardwood Creek Regional Trail is a 9.5-mile trail that starts in Hugo, MN alongside Highway 61 to the Washington/Chisago County Line in Forest Lake."
        imageSix="/locations/forest-lake/hardwood-creek-trail.png"
        imageSixAlt="Norhart Lifestyle Living Apartment Communities"
        imageSixPill="Forest Lake Hardwood Creek Trail"
        pillActive=""
        colorPalette={colorPalette}
        buttonName="Learn More"
        buttonUrl="https://www.ci.forest-lake.mn.us"
        buttonBackground="#FFFFFF"
        buttonText="#111111"
      />

      <GalleryImageSection
        backgroundColorOne={colorPalette.cardAccentTop}
        backgroundColorTwo={colorPalette.cardAccentTop}
        icon={ApartmentIcon}
        iconTitle="Series 2 Apartment"
        title="Join Us At Encore"
        subtitle="We invite you to be a part of our luxury modern apartment in Forest Lake, MN. At Norhart our teams have designed and built an exceptional living experience from the top down just for you!"
        imageTitleOne="Norhart Encore 1"
        imageOne="/apartments/gallery/encore/norhart-encore-1.webp"
        imageTitleTwo="Norhart Encore 2"
        imageTwo="/apartments/gallery/encore/norhart-encore-2.webp"
        imageTitleThree="Norhart Encore 3"
        imageThree="/apartments/gallery/encore/norhart-encore-3.webp"
        imageTitleFour="Norhart Encore 4"
        imageFour="/apartments/gallery/encore/norhart-encore-4.webp"
        imageTitleFive="Norhart Encore 5"
        imageFive="/apartments/gallery/encore/norhart-encore-5.webp"
        imageTitleSix="Norhart Encore 6"
        imageSix="/apartments/gallery/encore/norhart-encore-6.webp"
        buttonUrl="https://www.norhart.com/forest-lake/encore/"
        colorPalette={colorPalette}
        badge={false}
      />

      <FourImageSmallSection
        backgroundColorOne={colorPalette.heroTop}
        backgroundColorTwo={colorPalette.heroBottom}
        icon={BadgeCheckIcon}
        title="Apartment Nice Little Extras"
        subtitle="We invite you to choose a Norhart apartment as your next home. We have carefully crafted and curated a living experience that will enhance your life in every-way possible."
        imageOnePrice="$55/mo"
        imageHeaderOne="Add-on"
        imageTitleOne="Pet Friendly"
        imageSubTitleOne="We know how important your furry friends are :-)"
        imageOne="/apartments/norhart-apartments-pet-friendly.webp"
        imageOneAlt="Norhart Apartments are Pet Friendly"
        imageTwoPrice="$75+/mo"
        imageHeaderTwo="Add-on"
        imageTitleTwo="Parking"
        imageSubTitleTwo="With underground parking no more cold mornings!"
        imageTwo="/apartments/norhart-apartments-underground-parking.webp"
        imageTwoAlt="Norhart Undergroud Parking"
        imageThreePrice="Free"
        imageHeaderThree="Limited"
        imageTitleThree="Outdoor Balcony"
        imageSubTitleThree="Relax outside and soak up the sun and atmosphere."
        imageThree="/apartments/norhart-apartments-outdoor-balcony.webp"
        imageThreeAlt="Norhart Apartment Balcony"
        imageFourPrice="$50+/mo"
        imageHeaderFour="Limited"
        imageTitleFour="Internet"
        imageSubTitleFour="Plug and play with our in-unit WIFI $50+/mo."
        imageFour="/apartments/norhart-apartments-smart-home.webp"
        imageFourAlt="Norhart Smart Apartment App"
        priceActive={true}
        colorPalette={colorPalette}
        imageExtraOne="/apartments/gallery/extras/extras-1.webp"
        imageTitleExtraOne="Norhart Extra Image 1"
        imageExtraTwo="/apartments/gallery/extras/extras-2.webp"
        imageTitleExtraTwo="Norhart Extra Image 2"
        imageExtraThree="/apartments/gallery/extras/extras-3.webp"
        imageTitleExtraThree="Norhart Extra Image 3"
        imageExtraFour="/apartments/gallery/extras/extras-4.webp"
        imageTitleExtraFour="Norhart Extra Image 4"
        imageExtraFive="/apartments/gallery/extras/extras-5.webp"
        imageTitleExtraFive="Norhart Extra Image 5"
        imageExtraSix="/apartments/gallery/extras/extras-6.webp"
        imageTitleExtraSix="Norhart Extra Image 6"
        asoEffect="fade"
        buttonName="View Apartments"
        buttonUrl="/apartments/"
      />

      <HeroRightSection
        backgroundColorOne={colorPalette.cardAccentTop}
        backgroundColorTwo={colorPalette.cardAccentBottom}
        fontTextColor="#2f5265"
        titleTopLeft="Norhart Living"
        badgeTitle="Great Floorplans"
        title="Beautiful Living Areas"
        subtitle="Choose from one, two, or three-bedroom floor-plans. We carefully designed each space to be spacious, functional, and luxurious. Just for you!"
        imageTitle="Norhart Apartment Floor Plans"
        image="/apartments/norhart-apartments-floor-plan.png"
        colorPalette={colorPalette}
        colSizeOne={6}
        colSizeTwo={6}
        bottomMargin={5}
        asoEffect="fade"
      />

      <CompareSection />

      <HeroRightSection
        backgroundColorOne={colorPalette.heroTop}
        backgroundColorTwo={colorPalette.heroBottom}
        fontTextColor="#FFFFFF"
        titleTopLeft="History"
        badgeTitle="City History"
        title="Forest Lake, MN"
        subtitle="Forest Lake, MN has as very rich history. The city has been a resort destination for presidents and mobsters and more!"
        imageTitle="Forest Lake MN History"
        image="/history/forest-lake-history.png"
        buttonName="Learn More"
        buttonUrl="/history/forest-lake/"
        colorPalette={colorPalette}
        colSizeOne={4}
        colSizeTwo={8}
        bottomMargin={0}
        asoEffect="fade"
      />

      <NewsletterSignup colorPalette={colorPalette} />

      <AwardsSection
        header="Forest Lake Apartments"
        title="We Build More Than Apartments"
        subtitle="We have been called out for being an innovator, setting the bar high, and pushing the industry in a new direction. That's just who we are. Experience the Norhart difference for yourself!"
        colorPalette={colorPalette}
      />
    </Layout>
  )
}

/** export */
/** export */
export default ForestLakeLocationPage
